@import "./color-primitives.scss";

// Light Theme
:root {
  --border-dark: var(--color-warm-grey-600);
  --border-darker: var(--color-warm-grey-800);
  --border-error: var(--color-glo-coral-300);
  --border-light: var(--color-warm-grey-200);
  --border-lighter: var(--color-warm-grey-100);
  --border-lightest: var(--color-warm-grey-50);
  --border-medium: var(--color-warm-grey-300);
  --border-selected: var(--color-soft-blue-700);
  --border-selected-secondary: var(--color-soft-blue-500);
  --border-secondary: var(--color-cool-grey-700);
  --border-success: var(--color-green-100);
  --border-primary: var(--color-primary-glo-black);
  --ring-primary: var(--color-primary-glo-black);
  --surface-action-primary: var(--color-primary-glo-black);
  --surface-action-primary-disabled: var(--color-warm-grey-300);
  --surface-action-primary-down: var(--color-glo-white-sand-950);
  --surface-action-primary-hover: var(--color-warm-grey-800);
  --surface-action-secondary: var(--color-warm-grey-100);
  --surface-action-secondary-disabled: var(--color-warm-grey-100);
  --surface-action-secondary-down: var(--color-warm-grey-600);
  --surface-action-secondary-hover: var(--color-warm-grey-400);
  --surface-action-selected: var(--color-soft-blue-700);
  --surface-action-selected-disabled: var(--color-soft-blue-400);
  --surface-action-selected-hover: var(--color-soft-blue-800);
  --surface-action-selected-secondary: var(--color-soft-blue-600);
  --surface-action-tertiary: var(--color-primary-glo-white);
  --surface-action-tertiary-disabled: var(--color-warm-grey-100);
  --surface-action-tertiary-down: var(--color-warm-grey-200);
  --surface-action-tertiary-hover: var(--color-warm-grey-100);
  --surface-default-badge: var(--color-glo-sand-700);
  --surface-default-chip: var(--color-soft-blue-800);
  --surface-default-chip-overimage: var(--color-soft-blue-800);
  --surface-default-error-dark: var(--color-glo-coral-700);
  --surface-default-error-light: var(--color-glo-coral-100);
  --surface-default-hilite-aqua: var(--color-muted-aqua-200);
  --surface-default-hilite-blue: var(--color-soft-blue-200);
  --surface-default-hilite-coral: var(--color-glo-coral-200);
  --surface-default-hilite-glo-sand: var(--color-glo-sand-300);
  --surface-default-hilite-violet: var(--color-glo-violet-200);
  --surface-default-primary: var(--color-primary-glo-off-white);
  --surface-default-secondary: var(--color-primary-glo-white);
  --surface-default-success-dark: var(--color-green-600);
  --surface-default-success-light: var(--color-green-50);
  --surface-smoked-extra-lighter: #00000014;
  --surface-smoked-extra-lighter-overimage: #00000014;
  --surface-smoked-light: #0000003d;
  --surface-smoked-light-overimage: #0000003d;
  --surface-smoked-lighter: #00000029;
  --surface-smoked-lighter-overimage: #00000029;
  --surface-smoked-lightest: #0000000a;
  --surface-smoked-medium: #0000007a;
  --surface-smoked-medium-overimage: #0000007a;
  --surface-smoked-strong: #000000a3;
  --surface-smoked-strong-overimage: #000000a3;
  --surface-smoked-stronger: #000000cc;
  --surface-smoked-stronger-overimage: #000000cc;
  --surface-background-default: var(--color-primary-glo-white);
  --gray-1: #edf3f3;
  --text-icon-disabled-high-contrast: var(--color-cool-grey-500);
  --text-icon-disabled-high-contrast-overimage: var(--color-cool-grey-500);
  --text-icon-disabled-low-contrast: var(--color-cool-grey-100);
  --text-icon-disabled-low-contrast-overimage: var(--color-cool-grey-100);
  --text-icon-disabled-medium-contrast: var(--color-cool-grey-300);
  --text-icon-disabled-medium-contrast-overimage: var(--color-cool-grey-300);
  --text-icon-error: var(--color-glo-coral-800);
  --text-icon-inverse: var(--color-primary-glo-white);
  --text-icon-inverse-overimage: var(--color-primary-glo-white);
  --text-icon-primary: var(--color-primary-glo-black);
  --text-icon-primary-overimage: var(--color-primary-glo-black);
  --text-icon-secondary: var(--color-cool-grey-700);
  --text-icon-secondary-overimage: var(--color-cool-grey-700);
  --text-icon-success: var(--color-green-600);
  --text-icon-tag: var(--color-primary-glo-white);
  --text-icon-tag-disabled: #ffffff3d;
  --text-icon-tag-disabled-overimage: #ffffff3d;
  --text-icon-tag-overimage: var(--color-primary-glo-white);
  --text-icon-tag-text-only: var(--color-soft-blue-800);
  --text-icon-tertiary: var(--color-cool-grey-600);
  --text-icon-tertiary-overimage: var(--color-cool-grey-600);
}

// Dark Theme
[data-theme="dark"] {
  --border-dark: var(--color-warm-grey-300);
  --border-darker: var(--color-warm-grey-100);
  --border-error: var(--color-glo-coral-800);
  --border-light: var(--color-warm-grey-700);
  --border-lighter: var(--color-warm-grey-800);
  --border-lightest: var(--color-warm-grey-900);
  --border-medium: var(--color-warm-grey-600);
  --border-selected: var(--color-soft-blue-500);
  --border-selected-secondary: var(--color-soft-blue-700);
  --border-success: var(--color-green-700);
  --surface-action-primary: var(--color-warm-grey-100);
  --surface-action-primary-disabled: var(--color-warm-grey-600);
  --surface-action-primary-down: var(--color-warm-grey-50);
  --surface-action-primary-hover: var(--color-warm-grey-300);
  --surface-action-secondary: var(--color-warm-grey-700);
  --surface-action-secondary-disabled: var(--color-warm-grey-700);
  --surface-action-secondary-down: var(--color-warm-grey-300);
  --surface-action-secondary-hover: var(--color-warm-grey-500);
  --surface-action-selected: var(--color-soft-blue-500);
  --surface-action-selected-disabled: var(--color-soft-blue-800);
  --surface-action-selected-hover: var(--color-soft-blue-300);
  --surface-action-selected-secondary: var(--color-soft-blue-600);
  --surface-action-tertiary: var(--color-warm-grey-800);
  --surface-action-tertiary-disabled: var(--color-warm-grey-800);
  --surface-action-tertiary-down: var(--color-warm-grey-600);
  --surface-action-tertiary-hover: var(--color-warm-grey-700);
  --surface-default-badge: var(--color-glo-sand-600);
  --surface-default-chip: var(--color-soft-blue-300);
  --surface-default-chip-overimage: var(--color-soft-blue-800);
  --surface-default-error-dark: var(--color-glo-coral-600);
  --surface-default-error-light: var(--color-glo-coral-800);
  --surface-default-hilite-aqua: var(--color-muted-aqua-700);
  --surface-default-hilite-blue: var(--color-soft-blue-800);
  --surface-default-hilite-coral: var(--color-glo-coral-800);
  --surface-default-hilite-glo-sand: var(--color-glo-sand-700);
  --surface-default-hilite-violet: var(--color-glo-violet-700);
  --surface-default-primary: var(--color-warm-grey-800);
  --surface-default-secondary: var(--color-warm-grey-900);
  --surface-default-success-dark: var(--color-green-300);
  --surface-default-success-light: var(--color-green-800);
  --surface-smoked-extra-lighter: #ffffff0a;
  --surface-smoked-extra-lighter-overimage: #00000014;
  --surface-smoked-light: #ffffff29;
  --surface-smoked-light-overimage: #0000003d;
  --surface-smoked-lighter: #ffffff14;
  --surface-smoked-lighter-overimage: #00000029;
  --surface-smoked-lightest: #ffffff05;
  --surface-smoked-medium: #ffffff3d;
  --surface-smoked-medium-overimage: #0000007a;
  --surface-smoked-strong: #ffffff7a;
  --surface-smoked-strong-overimage: #000000a3;
  --surface-smoked-stronger: #ffffffa3;
  --surface-smoked-stronger-overimage: #000000cc;
  --surface-background-default: var(--color-glo-black);
  --gray-1: #0c1212;
  --text-icon-disabled-high-contrast: var(--color-cool-grey-600);
  --text-icon-disabled-high-contrast-overimage: var(--color-cool-grey-500);
  --text-icon-disabled-low-contrast: var(--color-cool-grey-800);
  --text-icon-disabled-low-contrast-overimage: var(--color-cool-grey-100);
  --text-icon-disabled-medium-contrast: var(--color-cool-grey-700);
  --text-icon-disabled-medium-contrast-overimage: var(--color-cool-grey-300);
  --text-icon-error: var(--color-glo-coral-600);
  --text-icon-inverse: var(--color-primary-glo-black);
  --text-icon-inverse-overimage: var(--color-cool-grey-200);
  --text-icon-primary: var(--color-cool-grey-200);
  --text-icon-primary-overimage: var(--color-primary-glo-black);
  --text-icon-secondary: var(--color-cool-grey-500);
  --text-icon-secondary-overimage: var(--color-cool-grey-700);
  --text-icon-success: var(--color-green-500);
  --text-icon-tag: var(--color-primary-glo-black);
  --text-icon-tag-disabled: #0000003d;
  --text-icon-tag-disabled-overimage: #ffffff3d;
  --text-icon-tag-overimage: var(--color-cool-grey-200);
  --text-icon-tag-text-only: var(--color-soft-blue-300);
  --text-icon-tertiary: var(--color-cool-grey-500);
  --text-icon-tertiary-overimage: var(--color-cool-grey-600);
}
