:root {
  --spacing-mobile-contract-S: 1rem;
  --spacing-mobile-contract-L: 2.5rem;
  --spacing-grid-h-padding: 1rem;
  --spacing-mobile-expand-S: 2.5rem;
  --spacing-fixed-M: 2.5rem;
  --spacing-fixed-L: 4rem;

  @include md {
    --spacing-mobile-contract-S: 1.5rem;
    --spacing-mobile-contract-L: 3rem;
    --spacing-grid-h-padding: 2.125rem;
    --spacing-mobile-expand-S: 1.5rem;
    --spacing-fixed-M: 2.5rem;
    --spacing-fixed-L: 4rem;
  }

  @include lg {
    --spacing-mobile-contract-S: 1.5rem;
    --spacing-mobile-contract-L: 4rem;
    --spacing-grid-h-padding: 4rem;
    --spacing-mobile-expand-S: 1.5rem;
    --spacing-fixed-M: 2.5rem;
    --spacing-fixed-L: 4rem;
  }
}
