/* button.tailwind.css */
.button-base {
  @apply inline-flex cursor-pointer items-center justify-center gap-2 rounded-xl text-center font-sanserif font-medium opacity-100 transition-all ease-in-out;
}

.button-large {
  @apply h-[var(--spacing-5xl)] min-w-[var(--min-width-hug)] px-[var(--spacing-xxxs)] py-[var(--spacing-base)] leading-[var(--fontstylesLabellineheight)] text-[var(--fontstylesLabelsize)];
}

.button-medium {
  @apply h-[var(--spacing-xl)] min-w-[var(--min-width-hug)] py-[var(--spacing-base)] pl-[var(--spacing-xl)] pr-[var(--spacing-base)] leading-[var(--fontstylesLabellineheight)] text-[var(--fontstylesLabelsize)];
}

.button-small {
  @apply h-[var(--spacing-xxl)] min-w-[106px] px-[var(--spacing-xxxs)] py-[var(--spacing-s)] leading-[var(--fontstylesLabellineheight)] text-[var(--fontstylesLabelsize)];
}

.button-compact {
  @apply h-[var(--spacing-xl)] min-w-[90px] px-[var(--spacing-xxxs)] py-[var(--spacing-xxs)] leading-[var(--fontstylesLabellineheight)] text-[var(--fontstylesLabelsize)];
}

.button-primary {
  @apply rounded-xl bg-surface-action-primary text-[var(--text-icon-inverse)];
  @apply hover:bg-surface-action-primary-hover hover:bg-opacity-[75%];
  @apply active:bg-surface-action-primary-down active:bg-opacity-[90%];
  @apply disabled:cursor-default disabled:bg-surface-action-primary-disabled disabled:text-[var(--text-icon-disabled-medium)] disabled:opacity-60;
}

.button-secondary {
  @apply rounded-xl bg-surface-action-secondary-disabled text-[var(--text-icon-secondary)];
  @apply hover:bg-surface-action-secondary-hover hover:bg-opacity-[75%];
  @apply active:bg-surface-action-secondary-down active:bg-opacity-[90%];
  @apply disabled:cursor-default disabled:bg-surface-action-secondary-disabled disabled:text-[var(--text-icon-disabled-dark)] disabled:opacity-60;
}

.button-tertiary {
  @apply rounded-xl border border-[var(--border-light)] bg-surface-action-tertiary text-[var(--text-icon-primary)];
  @apply hover:bg-surface-action-tertiary-hover hover:bg-opacity-[75%];
  @apply active:bg-surface-action-tertiary-down active:bg-opacity-[90%];
  @apply disabled:cursor-default disabled:border disabled:border-[var(--border-light)] disabled:bg-surface-action-tertiary-disabled disabled:text-[var(--text-icon-disabled-low-contrast)] disabled:opacity-60;
}

.button-borderless {
  @apply rounded-xl bg-transparent text-[var(--text-icon-primary)];
  @apply hover:bg-opacity-[75%];
  @apply active:bg-opacity-[90%];
  @apply disabled:cursor-default disabled:bg-transparent disabled:text-[var(--text-icon-disabled-medium)] disabled:opacity-60;
}

.button-outline {
  @apply rounded-xl border border-transparent bg-transparent text-[var(--text-icon-primary)];
  @apply hover:bg-opacity-[75%];
  @apply active:bg-opacity-[90%];
  @apply disabled:cursor-default disabled:bg-transparent disabled:text-[var(--text-icon-disabled-medium)] disabled:opacity-60;
}

.arrow {
  @apply h-[1em] w-[1em] text-inherit;
}

.arrow.disabled {
  @apply text-[var(--text-icon-disabled-medium)];
}
