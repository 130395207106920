:root {
  /* Spacing Tokens */
  --spacing-4xs: 4px;
  --spacing-xxxs: 8px;
  --spacing-xxs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-base: 24px;
  --spacing-l: 28px;
  --spacing-xl: 32px;
  --spacing-xxl: 36px;
  --spacing-xxxl: 40px;
  --spacing-4xl: 48px;
  --spacing-5xl: 56px;
  --spacing-6xl: 64px;
  --spacing-7xl: 72px;
  --spacing-8xl: 80px;

  /* Line Width Tokens */
  --line-width-half: 0.5px;
  --line-width-single: 1px;
  --line-width-double: 2px;
  --line-width-quad: 4px;

  /* Min Width Tokens */
  --min-width-hug: 120px;

  /* Intake Form Tokens */
  --max-width-intake-form: 660px;
  --horizontal-padding-intake-form: max(calc((100% - var(--max-width-intake-form)) / 2), var(--spacing-xs));

  --hug-48: 48px;
  --hug-72: 72px;
}
