@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./screen-size.scss";
@import "./button.tailwind.scss";
@import "./fonts.scss";
@import "./metrics.scss";
@import "./spacing.scss";
@import "../../../figma-design/color-themes.scss";
