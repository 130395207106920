:root {
  --color-cool-grey-100: #f2f2f3ff;
  --color-cool-grey-200: #e4e5e7ff;
  --color-cool-grey-300: #d4d5d8ff;
  --color-cool-grey-500: #b9bbc0ff;
  --color-cool-grey-600: #8a8d95ff;
  --color-cool-grey-700: #6a6e76ff;
  --color-cool-grey-800: #484b51ff;
  --color-glo-coral-100: #fdeae8ff;
  --color-glo-coral-200: #fbdad5ff;
  --color-glo-coral-300: #f8c5beff;
  --color-glo-coral-600: #ed5d4aff;
  --color-glo-coral-700: #d52c15ff;
  --color-glo-coral-800: #901e0eff;
  --color-glo-sand-300: #ede7e3ff;
  --color-glo-sand-600: #bea698ff;
  --color-glo-sand-700: #9e7b66ff;
  --color-glo-violet-200: #e5e4f2ff;
  --color-glo-violet-700: #5a51a4ff;
  --color-glo-white-sand-950: #211a0dff;
  --color-green-100: #caf7e2ff;
  --color-green-300: #65e6aaff;
  --color-green-50: #e5fbf0ff;
  --color-green-500: #1db56dff;
  --color-green-600: #179158ff;
  --color-green-700: #126e43ff;
  --color-green-800: #0b462bff;
  --color-muted-aqua-200: #d1eae5ff;
  --color-muted-aqua-700: #3f8d7dff;
  --color-primary-glo-black: #1d1e28ff;
  --color-primary-glo-off-white: #f4f3f2ff;
  --color-primary-glo-white: #ffffffff;
  --color-soft-blue-200: #dcecf9ff;
  --color-soft-blue-300: #cbe3f6ff;
  --color-soft-blue-400: #badaf2ff;
  --color-soft-blue-500: #a7d0efff;
  --color-soft-blue-600: #64ace3ff;
  --color-soft-blue-700: #2686cfff;
  --color-soft-blue-800: #19598aff;
  --color-warm-grey-100: #e6e5e6ff;
  --color-warm-grey-200: #cdcaceff;
  --color-warm-grey-300: #b6b2b8ff;
  --color-warm-grey-400: #9d989fff;
  --color-warm-grey-50: #f2f2f3ff;
  --color-warm-grey-500: #847e87ff;
  --color-warm-grey-600: #6a656cff;
  --color-warm-grey-700: #504c52ff;
  --color-warm-grey-800: #383539ff;
  --color-warm-grey-900: #1d1b1dff;
  --color-glo-black: #000000;
  --text-glo-error: #e86046;
  --color-glo-blue: #0055dc;
  --color-glo-gray-3: #bfc5c8;
  --color-glo-yellow: #ffe146;
  --border-low-contrast: #cdcace;
}
