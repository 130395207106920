/* Screen size breakpoints definition */

// Small devices
$breakpoint-sm: 640px;

// Medium devices
$breakpoint-md: 768px;

// Large devices
$breakpoint-lg: 1024px;

// Phones
@mixin sm {
  @media (min-width: #{$breakpoint-sm}) {
    @content;
  }
}

// Tablets
@mixin md {
  @media (min-width: #{$breakpoint-md}) {
    @content;
  }
}

// Computers
@mixin lg {
  @media (min-width: #{$breakpoint-lg}) {
    @content;
  }
}
