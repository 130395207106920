:root {
  /* Font Styles */
  --fontstylesDisplay1size: 5rem;
  --fontstylesDisplay1lineheight: 5rem;
  --fontstylesDisplay1letterspacing: normal;

  --fontstylesDisplay2size: 4rem;
  --fontstylesDisplay2lineheight: 4rem;
  --fontstylesDisplay2letterspacing: normal;

  --fontstylesDisplay3size: 3.5rem;
  --fontstylesDisplay3lineheight: 3.5rem;
  --fontstylesDisplay3letterspacing: normal;

  --fontstylesH1size: 3rem;
  --fontstylesH1lineheight: 3rem;
  --fontstylesH1letterspacing: normal;

  --fontstylesH2size: 2.5rem;
  --fontstylesH2lineheight: 3rem;
  --fontstylesH2letterspacing: normal;

  --fontstylesH3size: 2rem;
  --fontstylesH3lineheight: 2.25rem;
  --fontstylesH3letterspacing: normal;

  --fontstylesH4size: 1.75rem;
  --fontstylesH4lineheight: 2.5rem;
  --fontstylesH4letterspacing: normal;

  --fontstylesH5size: 1.25rem;
  --fontstylesH5lineheight: 1.75rem;
  --fontstylesH5letterspacing: normal;

  --fontstylesH6size: 1.125rem;
  --fontstylesH6lineheight: 1.5rem;
  --fontstylesH6letterspacing: normal;

  --fontstylesBodysize: 1rem;
  --fontstylesBodylineheight: 1.5rem;
  --fontstylesBodyletterspacing: normal;

  --fontstylesBodydensesize: 1rem;
  --fontstylesBodydenselineheight: 1.25rem;
  --fontstylesBodydenseletterspacing: normal;

  --fontstylesBodyemph1size: 1rem;
  --fontstylesBodyemph1lineheight: 1.5rem;
  --fontstylesBodyemph1letterspacing: normal;

  --fontstylesBodyemph1densesize: 1rem;
  --fontstylesBodyemph1denselineheight: 1.25rem;
  --fontstylesBodyemph1denseletterspacing: normal;

  --fontstylesBodyemph2size: 1rem;
  --fontstylesBodyemph2lineheight: 1.5rem;
  --fontstylesBodyemph2letterspacing: normal;

  --fontstylesBodyemph2densesize: 1rem;
  --fontstylesBodyemph2denselineheight: 1.25rem;
  --fontstylesBodyemph2denseletterspacing: normal;

  --fontstylesCaptionsize: 0.875rem;
  --fontstylesCaptionlineheight: 1.25rem;
  --fontstylesCaptionletterspacing: normal;

  --fontstylesCaptiondensesize: 0.75rem;
  --fontstylesCaptiondenselineheight: 1rem;
  --fontstylesCaptiondenseletterspacing: normal;

  --fontstylesCaptionemphsize: 0.875rem;
  --fontstylesCaptionemphlineheight: 1.25rem;
  --fontstylesCaptionemphletterspacing: normal;

  --fontstylesCaptionemphdensesize: 0.875rem;
  --fontstylesCaptionemphdenselineheight: 1rem;
  --fontstylesCaptionemphdenseletterspacing: normal;

  --fontstylesFootnotesize: 0.75rem;
  --fontstylesFootnotelineheight: 1rem;
  --fontstylesFootnoteletterspacing: normal;

  --fontstylesFootnotedensesize: 0.75rem;
  --fontstylesFootnotedenselineheight: 0.75rem;
  --fontstylesFootnotedenseletterspacing: normal;

  --fontstylesFootnoteemphsize: 0.75rem;
  --fontstylesFootnoteemphlineheight: 1rem;
  --fontstylesFootnoteemphletterspacing: normal;

  --fontstylesFootnoteemphdensesize: 0.75rem;
  --fontstylesFootnoteemphdenselineheight: 0.75rem;
  --fontstylesFootnoteemphdenseletterspacing: normal;

  --fontstylesLabelsmallsize: 0.75rem;
  --fontstylesLabelsmalllineheight: 1rem;
  --fontstylesLabelsmallletterspacing: normal;

  --fontstylesLabelsmallhoversize: 0.75rem;
  --fontstylesLabelsmallhoverlineheight: 1rem;
  --fontstylesLabelsmallhoverletterspacing: normal;

  --fontstylesLabelsize: 1.125rem;
  --fontstylesLabellineheight: 1.5rem;
  --fontstylesLabelletterspacing: normal;

  --fontstylesLabelhoversize: 1.125rem;
  --fontstylesLabelhoverlineheight: 1rem;
  --fontstylesLabelhoverletterspacing: normal;

  --fontstylesLabellargesize: 1.25rem;
  --fontstylesLabellargelineheight: 1.5rem;
  --fontstylesLabellargeletterspacing: normal;

  --fontstylesLabellargehoversize: 1.25rem;
  --fontstylesLabellargehoverlineheight: 1.5rem;
  --fontstylesLabellargehoverletterspacing: normal;

  --fontstylesUsernamesize: 1.25rem;
  --fontstylesUsernamelineheight: 1.25rem;
  --fontstylesUsernameletterspacing: normal;

  @include md {
    --fontstylesDisplay1size: 6.5rem;
    --fontstylesDisplay1lineheight: 6.5rem;

    --fontstylesDisplay2size: 5rem;
    --fontstylesDisplay2lineheight: 5rem;

    --fontstylesDisplay3size: 4rem;
    --fontstylesDisplay3lineheight: 4rem;

    --fontstylesH1size: 3.5rem;
    --fontstylesH1lineheight: 3.5rem;

    --fontstylesH2size: 2.75rem;
    --fontstylesH2lineheight: 3rem;

    --fontstylesH3size: 2.25rem;
    --fontstylesH3lineheight: 2.5rem;

    --fontstylesH4size: 2rem;
    --fontstylesH4lineheight: 2.5rem;

    --fontstylesH5size: 1.5rem;
    --fontstylesH5lineheight: 2rem;

    --fontstylesH6size: 1.25rem;
    --fontstylesH6lineheight: 1.75rem;

    --fontstylesBodysize: 1rem;
    --fontstylesBodylineheight: 1.5rem;

    --fontstylesBodydensesize: 1rem;
    --fontstylesBodydenselineheight: 1.25rem;

    --fontstylesBodyemph1size: 1rem;
    --fontstylesBodyemph1lineheight: 1.5rem;

    --fontstylesBodyemph1densesize: 1rem;
    --fontstylesBodyemph1denselineheight: 1.25rem;

    --fontstylesBodyemph2size: 1rem;
    --fontstylesBodyemph2lineheight: 1.5rem;

    --fontstylesBodyemph2densesize: 1rem;
    --fontstylesBodyemph2denselineheight: 1.25rem;

    --fontstylesCaptionsize: 0.875rem;
    --fontstylesCaptionlineheight: 1.25rem;

    --fontstylesCaptiondensesize: 0.75rem;
    --fontstylesCaptiondenselineheight: 1rem;

    --fontstylesCaptionemphsize: 0.875rem;
    --fontstylesCaptionemphlineheight: 1.25rem;

    --fontstylesCaptionemphdensesize: 0.875rem;
    --fontstylesCaptionemphdenselineheight: 1rem;

    --fontstylesFootnotesize: 0.75rem;
    --fontstylesFootnotelineheight: 1rem;

    --fontstylesFootnotedensesize: 0.75rem;
    --fontstylesFootnotedenselineheight: 0.75rem;

    --fontstylesFootnoteemphsize: 0.75rem;
    --fontstylesFootnoteemphlineheight: 1rem;

    --fontstylesFootnoteemphdensesize: 0.75rem;
    --fontstylesFootnoteemphdenselineheight: 0.75rem;

    --fontstylesLabelsmallsize: 0.75rem;
    --fontstylesLabelsmalllineheight: 1rem;

    --fontstylesLabelsmallhoversize: 0.75rem;
    --fontstylesLabelsmallhoverlineheight: 1rem;

    --fontstylesLabelsize: 1.125rem;
    --fontstylesLabellineheight: 1.5rem;

    --fontstylesLabelhoversize: 1.125rem;
    --fontstylesLabelhoverlineheight: 1rem;

    --fontstylesLabellargesize: 1.25rem;
    --fontstylesLabellargelineheight: 1.5rem;

    --fontstylesLabellargehoversize: 1.25rem;
    --fontstylesLabellargehoverlineheight: 1.5rem;

    --fontstylesUsernamesize: 1.25rem;
    --fontstylesUsernamelineheight: 1.25rem;
  }

  @include lg {
    --fontstylesDisplay1size: 7.75rem;
    --fontstylesDisplay1lineheight: 7.75rem;

    --fontstylesDisplay2size: 6rem;
    --fontstylesDisplay2lineheight: 6rem;

    --fontstylesDisplay3size: 4.5rem;
    --fontstylesDisplay3lineheight: 4.5rem;

    --fontstylesH1size: 4rem;
    --fontstylesH1lineheight: 4rem;

    --fontstylesH2size: 3rem;
    --fontstylesH2lineheight: 3.5rem;

    --fontstylesH3size: 2.5rem;
    --fontstylesH3lineheight: 2.75rem;

    --fontstylesH4size: 2rem;
    --fontstylesH4lineheight: 2.5rem;

    --fontstylesH5size: 1.5rem;
    --fontstylesH5lineheight: 2rem;

    --fontstylesH6size: 1.25rem;
    --fontstylesH6lineheight: 1.75rem;

    --fontstylesBodysize: 1rem;
    --fontstylesBodylineheight: 1.5rem;

    --fontstylesBodydensesize: 1rem;
    --fontstylesBodydenselineheight: 1.25rem;

    --fontstylesBodyemph1size: 1rem;
    --fontstylesBodyemph1lineheight: 1.5rem;

    --fontstylesBodyemph1densesize: 1rem;
    --fontstylesBodyemph1denselineheight: 1.25rem;

    --fontstylesBodyemph2size: 1rem;
    --fontstylesBodyemph2lineheight: 1.5rem;

    --fontstylesBodyemph2densesize: 1rem;
    --fontstylesBodyemph2denselineheight: 1.25rem;

    --fontstylesCaptionsize: 0.875rem;
    --fontstylesCaptionlineheight: 1.25rem;

    --fontstylesCaptiondensesize: 0.875rem;
    --fontstylesCaptiondenselineheight: 1rem;

    --fontstylesCaptionemphsize: 0.875rem;
    --fontstylesCaptionemphlineheight: 1.25rem;

    --fontstylesCaptionemphdensesize: 0.875rem;
    --fontstylesCaptionemphdenselineheight: 1rem;

    --fontstylesFootnotesize: 0.75rem;
    --fontstylesFootnotelineheight: 1rem;

    --fontstylesFootnotedensesize: 0.75rem;
    --fontstylesFootnotedenselineheight: 0.75rem;

    --fontstylesFootnoteemphsize: 0.75rem;
    --fontstylesFootnoteemphlineheight: 1rem;

    --fontstylesFootnoteemphdensesize: 0.75rem;
    --fontstylesFootnoteemphdenselineheight: 0.75rem;

    --fontstylesLabelsmallsize: 1rem;
    --fontstylesLabelsmalllineheight: 1rem;

    --fontstylesLabelsmallhoversize: 1rem;
    --fontstylesLabelsmallhoverlineheight: 1rem;

    --fontstylesLabelsize: 1.125rem;
    --fontstylesLabellineheight: 1.5rem;

    --fontstylesLabelhoversize: 1.125rem;
    --fontstylesLabelhoverlineheight: 1rem;

    --fontstylesLabellargesize: 1.25rem;
    --fontstylesLabellargelineheight: 1.5rem;

    --fontstylesLabellargehoversize: 1.25rem;
    --fontstylesLabellargehoverlineheight: 1.5rem;

    --fontstylesUsernamesize: 1.25rem;
    --fontstylesUsernamelineheight: 1.25rem;
  }
}
